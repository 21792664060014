import React from "react";

export default function MainBodyContent() {
  return (
    <>
      <p className="text-center fs-4">
        Download Other Resources /Past Question Papers / Quiz /MCQ's
      </p>
      <p>
        Quiz / MCQ Link :{" "}
        <a title="MCQ | QPKendra" href="https://quiz.qpkendra.com/">
          MCQ | QPKendra
        </a>
      </p>
      <br />
      <hr />
      <br />
      <p className="text-center fs-4">Engineering Question Papers :</p>
      <p>
        Mumbai University Past Engineering Question Papers:{" "}
        <a
          title="Mumbai University Past Question Papers | QPkendra"
          href="https://qpkendra.com/degree/index.html"
          rel="noreferrer"
        >
          Mumbai University Past Question Papers | QPkendra
        </a>
      </p>
      <div className="table-responsive">
        <table className="table table-striped table-bordered align-middle">
          <tbody>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                COMPUTER ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                CIVIL ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                MECHANICAL ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                INFORMATION TECH ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                ELECTRICAL ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                ELECTRONICS ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                BIOTECHNOLOGY ENGINEERING
              </td>
            </tr>
            <tr style={{ height: "18px" }}>
              <td style={{ height: "18px", width: "386.188px" }}>
                ELECTRONICS &amp; TELECOMMUNICATION ENGINEERING
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <hr />
      <br />
      <p className="fs-4">Diploma Question Papers / Model Answer Papers:</p>
      <ul>
        <li>
          MSBTE Model Answer Papers :{" "}
          <a
            title="MSBTE Model Answer Papers | QPkendra"
            href="https://qpkendra.com/diploma/index.html"
            target="_blank"
            rel="noreferrer"
          >
            MSBTE Model Answer Papers | QPkendra
          </a>
        </li>
      </ul>
      <br />
      <hr />
      <br />
      <p className="fs-4">Commerce Question Papers :</p>
      <div>
        <ul>
          <li>
            Mumbai University Question Commerce Papers :{" "}
            <a
              title="Mumbai University Past Question Papers | QPkendra"
              href="https://qpkendra.com/commerce/index.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University Past Commerce Question Papers | QPkendra
            </a>
          </li>
        </ul>
        <ul>
          <li>
            BCOM SEM 5 Past Question Papers :{" "}
            <a
              title="Mumbai University BCOm SEM 5 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBCOM-SEM5.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BCOm SEM 5 Papers | QPkendra
            </a>
          </li>
          <li>
            BCOM SEM 6 Past Question Papers :{" "}
            <a
              title="Mumbai University BCOm SEM 6 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBCOM-SEM6.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BCOm SEM 6 Papers | QPkendra
            </a>
          </li>
        </ul>
        <ul>
          <li>
            BBI SEM 5 Past Question Papers :{" "}
            <a
              title="Mumbai University BBI SEM 5 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBBI-SEM5.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BBI SEM 5 Papers | QPkendra
            </a>
          </li>
          <li>
            BBI SEM 6 Past Question Papers :{" "}
            <a
              title="Mumbai University BBI SEM 6 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBBI-SEM6.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BBI SEM 6 Papers | QPkendra
            </a>
          </li>
        </ul>
        <ul>
          <li>
            TYBAF SEM 5 Past Question Papers :{" "}
            <a
              title="Mumbai University TYBAF SEM 5 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBAF-SEM5.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University TYBAF SEM 5 Papers | QPkendra
            </a>
          </li>
          <li>
            TYBAF SEM 6 Past Question Papers :{" "}
            <a
              title="Mumbai University TYBAF SEM 6 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBAF-SEM6.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University TYBAF SEM 6 Papers | QPkendra
            </a>
          </li>
        </ul>
        <ul>
          <li>
            TYBFM SEM 5 Past Question Papers :{" "}
            <a
              title="Mumbai University TYBFM SEM 5 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBFM-SEM5.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University TYBFM SEM 5 Papers | QPkendra
            </a>
          </li>
          <li>
            TYBFM SEM 6 Past Question Papers :{" "}
            <a
              title="Mumbai University TYBFM SEM 6 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBFM-SEM6.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University TYBFM SEM 6 Papers | QPkendra
            </a>
          </li>
        </ul>
        <ul>
          <li>
            BMS SEM 5 Past Question Papers :{" "}
            <a
              title="Mumbai University BMS SEM 5 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBMS-SEM5.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BMS SEM 5 Papers | QPkendra
            </a>
          </li>
          <li>
            BMS SEM 6 Past Question Papers :{" "}
            <a
              title="Mumbai University BMS SEM 6 Papers | QPkendra"
              href="https://qpkendra.com/commerce/TYBMS-SEM6.html"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai University BMS SEM 6 Papers | QPkendra
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
