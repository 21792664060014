import React from 'react'

export default function Footer() {
  return (
    <>
     
<div className="container fot1" id="footr12">
  <footer className="py-3 my-4">
    <ul className="nav justify-content-center border-bottom pb-2 mb-2">
      <li className="nav-item"><a href="/#" target="_blank" rel="noopener noreferrer" title="Home"
          className="nav-link px-2 text-muted">Home</a></li>
      <li className="nav-item"><a href="https://qpkendra.com/#file.qpkendra.com" target="_blank" rel="noopener noreferrer"
          title="Find Past Question Paper" className="nav-link px-2 text-muted">Question Papers</a></li>
      <li className="nav-item"><a href="https://qpkendra.com/faq.html#file.qpkendra.com" target="_blank"
          rel="noopener noreferrer" title="Frequently Asked Question's" className="nav-link px-2 text-muted">FAQs</a></li>
      <li className="nav-item"><a href="https://qpkendra.com/sitemap.html#file.qpkendra.com" target="_blank"
          rel="noopener noreferrer" title="Sitemap" className="nav-link px-2 text-muted">Sitemap</a></li>
    </ul>
    <ul className="nav justify-content-center mb-1">
    <li className="nav-item">
    <p className="text-center text-muted">&copy;2022&nbsp;&nbsp;|&nbsp;&nbsp;QPKendra&nbsp;&nbsp;|&nbsp;&nbsp;</p>
  </li>
  <li className="nav-item">
    <p className="text-center text-muted">Made ❤️ QPkendra &nbsp;&nbsp;|&nbsp;&nbsp; <span className="text-secondary veron">v1.1(U89ver)</span></p>
  </li>
    </ul>
  </footer>
</div>
          

    </>
  )
}
